import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPath } from "../../../config";
import { toast } from "react-toastify";
import axios from "axios";
import filesIcon from "../../../images/files-icon.svg";
// import "./UploadFile.css"; // Import the CSS file for styling

function UploadFile(props) {
  const { getIdTokenClaims } = useAuth0();
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});

  const onDrop = useCallback(async (acceptedFiles) => {
    const maxFileSize = 50 * 1024 * 1024; // 50MB limit
    const validFiles = acceptedFiles.filter(file => file.size <= maxFileSize);

    if (validFiles.length !== acceptedFiles.length) {
      setError("Some files exceed the 50MB size limit");
    } else {
      setError(null);
    }

    if (validFiles.length > 0) {
      setUploading(true); // Start the upload process
      const previewFiles = validFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setFiles(prevFiles => [...prevFiles, ...previewFiles]);

      try {
        const uploadURLs = await getUploadURLs(validFiles);
        await Promise.all(validFiles.map((file, index) => uploadFileToURL(file, uploadURLs[index].url)));
        console.log("Files uploaded successfully");
      } catch (error) {
        console.error("Error uploading files:", error);
        setError("Error uploading files. Please try again.");
      } finally {
        setUploading(false); // End the upload process
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
      'text/plain': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': []
    },
    maxFiles: 10
  });

  const getUploadURLs = async (files) => {
    try {
      const filesArray = files.map((file) => ({
        filename: file.path,
        content_type: file.type
      }));
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const formattedData = JSON.stringify({ items: filesArray });
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: formattedData,
      };
      const ks_id = props.ks_id; // Replace with actual ks_id
      const path = `/ks_items?ks_id=${ks_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      return result; // Return the array of file objects with URLs
    } catch (error) {
      console.error("Error fetching upload URLs:", error);
      throw error;
    }
  };

  const uploadFileToURL = async (file, uploadURL) => {
    const toastid = toast.loading("Uploading file...", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    try {
      const response = await axios.put(uploadURL, file, {
        headers: {
          "Content-Type": file.type
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(prevProgress => ({
            ...prevProgress,
            [file.name]: percentCompleted
          }));
        }
      });
      if (response.status !== 200) {
        throw new Error(`Upload failed! Status: ${response.status}`);
      }
      toast.update(toastid, {
        render: "Files uploaded successfully",
        type: "success",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      props.setIsFileUploaded(true);
      setFiles([]);
    } catch (error) {
      toast.update(toastid, {
        render: "Error uploading file",
        type: "error",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      console.error("Error uploading file to URL:", error);
      throw error;
    }
  };

  return (
    <section className="container">
      {uploading ? (
        <h2 className="files-uploading-heading">Uploading files... Please wait.</h2>
      ) : (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>Upload files<br />Drop files directly or click to select files to upload</p>
        </div>
      )}
      {error && <div className="error">{error}</div>}
      <aside className="thumbs-container">
        {files.map((file, index) => (
          <div className="thumb" key={index}>
            <div className="thumb-inner">
              <img src={filesIcon} alt={file.name} />
            </div>
            <div className="filename">{file.name}</div>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${uploadProgress[file.name] || 0}%` }}></div>
            </div>
          </div>
        ))}
      </aside>
    </section>
  );
}

export default UploadFile;
