import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { useAuth0 } from "@auth0/auth0-react";
import { startOfWeek, endOfWeek, parseISO, format } from 'date-fns';

function ResponseCountChart(props) {
    const { range, start_date, end_date, channel, setLoading } = props;
    const { user } = useAuth0();
    const [data, setData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [resultCount, setResultCount] = useState(null);

    const getResponseCount = async (type, lookback, channel, startDate, endDate) => {
        try {
            setLoading(true);
            const getData = await props.getReports(type, lookback, null, channel, startDate, endDate);
            if(getData.error) {
                setErrorMessage(getData.error);
                setData([]);
                setResultCount(0);
            }else {
                setErrorMessage(null);
                setData(getData);
                setResultCount(getData.result_count);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (user) {
            getResponseCount("response_count", range);
        }
    }, [user]);

    useEffect(() => {
        getResponseCount("response_count", range, props.channel, start_date, end_date);
    }, [range, channel, start_date, end_date]);
                

    useEffect(() => {
        if (!data || data.length === 0) {
            return;
        }

        const chartDom = document.getElementById('res-chart');
        const myChart = echarts.init(chartDom);

        // Handle window resize
        const handleResize = () => {
            myChart.resize();
        };

        window.addEventListener('resize', handleResize);

        // Clean up the chart and event listener on unmount or when data changes
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', handleResize);
        };
    }, [data]);

    useEffect(() => {
        if (!data || data.length === 0) {
            return;
        }

        const chartDom = document.getElementById('res-chart');
        const myChart = echarts.init(chartDom);

        // Given JSON data
        const jsonData = data;

        // Prepare the data
        let labels, seriesData;

        if (range === 30) {
            labels = jsonData.labels;
            seriesData = jsonData.data.map((item, index) => {
                const colors = ['#053F98', '#4A558A', '#4D88A3', '#96D4E5'];
                return {
                    name: `Channel ${item.channel_id}`,
                    type: 'bar',
                    stack: 'total',
                    data: item.data,
                    itemStyle: {
                        color: colors[index % colors.length]
                    }
                };
            });
        } else {
            const weekRanges = {};
            jsonData.labels.forEach((label, index) => {
                const date = parseISO(label);
                const startOfWeekDate = startOfWeek(date);
                const endOfWeekDate = endOfWeek(date);
                const weekRange = `${format(startOfWeekDate, 'MMM d')} - ${format(endOfWeekDate, 'd')}`;
                if (!weekRanges[weekRange]) {
                    weekRanges[weekRange] = {};
                }
                jsonData.data.forEach(item => {
                    if (!weekRanges[weekRange][item.channel_id]) {
                        weekRanges[weekRange][item.channel_id] = 0;
                    }
                    weekRanges[weekRange][item.channel_id] += item.data[index];
                });
            });

            labels = Object.keys(weekRanges);
            seriesData = jsonData.data.map((item, index) => {
                const colors = ['#053F98', '#4A558A', '#4D88A3', '#96D4E5'];
                return {
                    name: `Channel ${item.channel_id}`,
                    type: 'bar',
                    stack: 'total',
                    data: labels.map(week => weekRanges[week][item.channel_id] || 0),
                    itemStyle: {
                        color: colors[index % colors.length]
                    }
                };
            });
        }

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow' // Default is 'line', but you can also set to 'shadow'
                }
            },
            legend: {
                data: seriesData.map(item => item.name),
                type: 'scroll',
                orient: 'horizontal',
                top: 10,
                bottom: 'auto',
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: labels
            },
            yAxis: {
                type: 'value'
            },
            series: seriesData
        };

        myChart.setOption(option);

        // Handle window resize
        const handleResize = () => {
            myChart.resize();
        };

        window.addEventListener('resize', handleResize);

        // Clean up the chart and event listener on unmount or when data changes
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', handleResize);
        };

    }, [data]);
    

    return (
        <div>
            <h2>Response Count</h2>
            {resultCount === 0 && (
              <div className="no-data">A chart of the response counts will show up here one day after you have your first AI response.</div>
            )}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div id="res-chart" style={{ height: 400, width: '100%' }}></div>
        </div>
    );
}

export default ResponseCountChart;
