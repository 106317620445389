import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import MainAppWrap from "../../../layout/MainAppWrap";
import SimpleHeader from "../../SimpleHeader";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { set } from "date-fns";
import { apiPath } from "../../../config";

function AddChannel() {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const [channelName, setChannelName] = useState("");
  const [confidential, setConfidential] = useState(false);
  const [channelType, setChannelType] = useState("");
  const [channelKsc, setChannelKsc] = useState("");
  const [channelCompanyName, setChannelCompanyName] = useState("");
  const [channelUrl, setChannelUrl] = useState("");
  const [showUrl, setShowUrl] = useState(false);

  const [kscList, setKscList] = useState([]);
  const [isLoadingKSC, setIsLoadingKSC] = useState(false);

  const [responseWait, setResponseWait] = useState(true);

  const [responseSlackUrl, setResponseSlackUrl] = useState("");
  const [responseZendeskUrl, setResponseZendeskUrl] = useState("");
  const [responseHubspotUrl, setResponseHubspotUrl] = useState("");

  const fetchKcs = async () => {
    try {
      setIsLoadingKSC(true);
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/ksc_list`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log("ksc-", result);
      setKscList(result);
      setIsLoadingKSC(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingKSC(false);
    }
  };

  const toastHandler = (message, type) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // add validation here
    if (channelName.length === 0) {
      toastHandler("Please enter name", "error");
      return;
    }
    if (channelType.length === 0) {
      toastHandler("Please select type", "error");
      return;
    }
    if (channelKsc.length === 0) {
      toastHandler("Please select ksc", "error");
      return;
    }
    if (channelCompanyName.length === 0) {
      toastHandler("Please enter company name", "error");
      return;
    }
    if (
      channelType.value === "zendesk" ||
      channelType.value === "community_forum"
    ) {
      if (channelUrl.length === 0) {
        toastHandler("Please enter url", "error");
        return;
      }
    }

    if (e.target.classList.contains("disabled")) {
      return null;
    }
    e.target.classList.add("disabled");

    let data = {};
    data.name = channelName;
    data.type = channelType.value;
    data.ksc_id = channelKsc.value;
    data.company_name = channelCompanyName;
    data.confidential = confidential;

    if (
      channelType.value === "zendesk" ||
      channelType.value === "community_forum"
    ) {
      data.url = channelUrl;
    }

    if (channelType.value === "websocket") {
      data.url = 'was://ws.api.ept.ai';
    }

    data = JSON.stringify(data);
    // console.log(data);
    putChannel(data);
  };

  const putChannel = async (data) => {
    const toastid = toast.loading("Please wait...", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    try {
      if (
        channelType.value === "zendesk" ||
        channelType.value === "hubspot" ||
        channelType.value === "slack"
      ) {
        setResponseWait(true);
      }

      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: data,
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/channel`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result);
      toast.update(toastid, {
        render: "Channel added successfully!",
        type: "success",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      //   document.getElementById("request_new_source").classList.remove("show");
      // setChannelName("");
      // setChannelType("");
      // setChannelKsc("");
      // setChannelCompanyName("");
      // setConfidential(false);
      if (
        channelType.value === "zendesk" ||
        channelType.value === "hubspot" ||
        channelType.value === "slack"
      ) {
        setResponseWait(false);

        switch (channelType.value) {
          case "zendesk":
            setResponseZendeskUrl(result.url);
            break;
          case "hubspot":
            setResponseHubspotUrl(result.url);
            break;
          case "slack":
            setResponseSlackUrl(result.url);
            break;
          default:
            break;
        }
      } else {
        setTimeout(() => {
          navigate("/settings/channel/" + result.channel_id);
        }, 3200);
      }

      document.getElementById("ks-submit-btn").classList.remove("disabled");
    } catch (error) {
      console.error("Error fetching data:", error);
      document.getElementById("ks-submit-btn").classList.remove("disabled");
      toast.update(toastid, {
        render: "Error adding channel!",
        type: "error",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    if (kscList.length === 0) {
      fetchKcs();
    }
  }, [user]);

  const handleKscChange = (selectedOption) => {
    setChannelKsc(selectedOption);
  };

  const handleChannelChange = (selectedOption) => {
    setChannelType(selectedOption);
  };

  useEffect(() => {
    if (
      channelType.value === "zendesk" ||
      channelType.value === "community_forum"
    ) {
      setShowUrl(true);
    } else {
      setShowUrl(false);
    }
  }, [channelType]);

  const handleConnectCancel = (e) => {
    e.preventDefault();
    setResponseWait(true);
    setResponseSlackUrl("");
    setResponseZendeskUrl("");
    setResponseHubspotUrl("");
    setChannelName("");
    setChannelType("");
    setChannelKsc("");
    setChannelCompanyName("");
    setConfidential(false);
    setChannelUrl("");
  }

  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader />
        <Container fluid="xxl" className="ks-form">
          <Row>
            <Col className="settings-header">
              <h1>Create Channel</h1>
            </Col>
          </Row>
          <Row>
            {responseWait && (
              <Col>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="channel-name">
                        Name:
                        <input
                          type="text"
                          className="form-control"
                          id="channel-name"
                          name="channel-name"
                          placeholder=""
                          value={channelName}
                          onChange={(e) => setChannelName(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <label>
                        {" "}
                        Select Type:
                        <Select
                          id="type"
                          onChange={handleChannelChange}
                          value={channelType}
                          className="form-select-new"
                          options={[
                            {
                              value: "community_forum",
                              label: "Community forum",
                            },
                            { value: "chat", label: "Chat" },
                            { value: "email", label: "Email" },
                            { value: "hubspot", label: "hubspot" },
                            { value: "zendesk", label: "zendesk" },
                            { value: "slack", label: "slack" },
                            { value: "websocket", label: "websocket" },
                            {value:"ept-ai-app", label:"ept AI App"},
                          ]}
                          isSearchable
                        />
                      </label>
                    </div>
                  </div>
                  {showUrl && (
                    <div className="col-12">
                      <div className="input-group">
                        <label htmlFor="channel-url">
                          {channelType.value === "community_forum"
                            ? "URL:"
                            : "Zendesk subdomain:"}
                          <input
                            type="text"
                            className="form-control"
                            id="channel-url"
                            name="channel-url"
                            placeholder=""
                            value={channelUrl}
                            onChange={(e) => setChannelUrl(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                    <div className="input-group">
                      <label>
                        {" "}
                        Select KSC:
                        <Select
                          id="select-ks"
                          className="form-select-new"
                          onChange={handleKscChange}
                          value={channelKsc}
                          options={kscList.map((ksc) => {
                            return { value: ksc.ksc_id, label: ksc.name };
                          })}
                          isSearchable
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="channel-company-name">
                        Company name:
                        <input
                          type="text"
                          className="form-control"
                          id="channel-company-name"
                          name="channel-company-name"
                          placeholder=""
                          value={channelCompanyName}
                          onChange={(e) =>
                            setChannelCompanyName(e.target.value)
                          }
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <div className="col-xs-12 custom-response-checkbox td-relative">
                        <label htmlFor="pop-source-confidentials-1">
                          <span className="checkbox-label">Confidential</span>
                          <input
                            type="checkbox"
                            id="pop-source-confidentials-1"
                            name="pop-source-confidentials"
                            checked={confidential}
                            onChange={(e) => setConfidential(e.target.checked)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="ks-footer">
                    <Link
                      to="/settings/channels"
                      className="delete-user-modal-btn cancel"
                    >
                      Cancel
                    </Link>
                    <a
                      href="#"
                      id="ks-submit-btn"
                      className="request_btn"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Create
                    </a>
                  </div>
                </div>
              </Col>
            )}
            {!responseWait && (
              <Col>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group">
                      {responseSlackUrl !== "" && (
                        <div>
                          <p>Connect your company Slack account to ept AI to get instant ept AI answers right in Slack.  ept AI can only see messages in channels where ept AI is invited. </p>
                          <a
                            href={responseSlackUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="connect_btn slack"
                          >
                            Connect to Slack
                          </a>
                          <a href="#" className="delete-user-modal-btn cancel" onClick={handleConnectCancel}>Cancel</a>
                        </div>
                      )}
                      {responseZendeskUrl !== "" && (
                        <div>
                          <p>Connect your company Zendesk account to get ept AI draft answers to every support ticket.</p>
                          <a
                            href={responseZendeskUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="connect_btn zendesk"
                          >
                            Connect to Zendesk
                          </a>
                          <a href="#" className="delete-user-modal-btn cancel" onClick={handleConnectCancel}>Cancel</a>
                        </div>
                      )}
                      {responseHubspotUrl !== "" && (
                        <div>
                          <p>Connect your company HubSpot account to ept AI to get ept AI answers as drafts to HubSpot form submissions and support tickets.</p>
                          <a
                            href={responseHubspotUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="connect_btn hubspot"
                          >
                            Connect to Hubspot
                          </a>
                          <a href="#" className="delete-user-modal-btn cancel" onClick={handleConnectCancel}>Cancel</a>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </MainAppWrap>
  );
}

export default AddChannel;
